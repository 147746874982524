<template>
  <v-row>
    <v-col cols="12" style="padding:0px !important;">
      <v-carousel
            class="hidden-sm-and-down"
            :show-arrows="false"
            hide-delimiters
            interval="12000"
            height="500"
        >
            <v-carousel-item
            v-for="(item, i) in slides"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
            >
            <div class="overlay">
                <v-row class="fill-height" style="padding-top:150px;">
                <v-col cols="11" offset="1" style="color:#ffffff;">
                    <v-layout wrap class="text-h2" style="color:#ffffff;">
                    <b>{{ item.title }}</b>
                    </v-layout>
                    <!-- <p class="ma-5" style="font-size: 18px;">
                    {{ item.text }}
                    </p> -->
                    <!-- <v-layout wrap>
                    <v-btn rounded color="#72bd61" dark>{{ item.btnText }}</v-btn>
                    </v-layout> -->
                </v-col>
                </v-row>
            </div>
            </v-carousel-item>
        
      </v-carousel>
      <v-carousel
            class="hidden-md-and-up"
            cycle
            :show-arrows="false"
            hide-delimiters
            height="200px"
            interval="10000"
        >
            <v-carousel-item
            v-for="(item, i) in slides"
            :key="i"
            :src="item.src"
            transition="fade"
            reverse-transition="fade"
            >
            <div class="overlay">
                <v-row class="fill-height pt-5">
                <v-col cols="8" offset="1" style="color:#ffffff;">
                    <v-layout wrap class="text-h6" style="color:#ffffff;">
                    {{ item.title }}
                    </v-layout>
                    <!-- <p>
                    {{ item.text }}
                    </p>
                    <v-layout wrap>
                    <v-btn rounded color="#72bd61" dark small>{{
                        item.btnText
                    }}</v-btn>
                    </v-layout> -->
                </v-col>
                </v-row>
            </div>
            </v-carousel-item>
      </v-carousel>

      <v-container fluid >
        <v-layout wrap>
             
              <v-col cols="12">
                  <p>
                    qikPharma prioritizes user satisfaction and fosters a secure trading environment through our comprehensive Trade Assurance Policy. 
                    This policy safeguards transactions and upholds the highest ethical standards, ensuring a seamless and reliable experience for all users.
                  </p>

                  <h4><b>Key Pillars of the qikPharma Trade Assurance Policy:</b></h4>
                    <p>
                        <ol>
                            <li><b>Steadfast Security:</b>
                              We leverage advanced encryption and security protocols to guarantee secure transactions throughout the buying and selling process. 
                              This protects your sensitive information and financial data, providing peace of mind for all parties involved.
                            </li>
                            <li><b>Quality Commitment:</b>
                              qikPharma collaborates closely with reputable retailers, manufacturers, and distributors to ensure the utmost quality of every 
                              product listed on our platform. All products are subjected to strict quality standards and regulatory requirements.
                            </li>
                            <li><b>Timely Deliveries:</b>
                              We understand the urgency associated with healthcare products. Our Trade Assurance Policy prioritizes prompt order
                               processing and on-time deliveries, ensuring you receive your purchases without delays.
                            </li>
                            <li><b>Efficient Dispute Resolution:</b>
                              In the uncommon instance of a dispute, qikPharma acts as a neutral mediator to facilitate swift and fair resolution. 
                              Our dedicated support team is readily available to assist users in finding mutually agreeable solutions.
                            </li>
                            <li><b>Buyer Protection:</b>
                              Purchase with confidence. The qikPharma Trade Assurance Policy safeguards buyers against fraudulent activity, 
                              counterfeit products, and non-delivery. In the event of any issues, you are entitled to a full reimbursement.
                            </li>
                            <li><b>Seller Accountability:</b>
                              qikPharma maintains a rigorous seller vetting process, verifying credentials and reputation to ensure a trustworthy and 
                              transparent marketplace for everyone. Sellers are held to the highest standards of accountability.
                            </li>
                            <li><b>Continuous Improvement:</b>
                              We are dedicated to continual enhancement of our Trade Assurance Policy to better serve our user base. 
                              Your feedback is crucial as we strive for excellence in every aspect of our platform.
                            </li>
                            <li><b>Uncompromising Integrity:</b>
                              At qikPharma, we operate with firm principles of integrity, honesty, and fairness in all transactions. 
                              Our Trade Assurance Policy reflects this core value and our commitment to earning and maintaining user trust.
                            </li>
                        </ol>
                    </p>
                    <p>
                      With qikPharma's Trade Assurance Policy, you can engage in e-commerce with confidence. Industry-leading security measures and a commitment to excellence safeguard your transactions. 
                      Welcome to a smarter, safer, and more reliable trading experience with qikPharma.
                    </p>
              </v-col>
        </v-layout>

      </v-container>

    
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "About",

  data: () => ({
      slides: [
        {
          src: "https://res.cloudinary.com/dxw06ktju/image/upload/v1697799385/Slider-3_bczg17.png",
          title: "qikPharma Trade Assurance Policy",
          text: "Protect Your qikPharma Orders.",
          btnText: "Learn How it Works",
          link: "#",
        },
      ],
  })
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.box1 {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff;
}

.box2 {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  color: #171a17;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

p {
  line-height: 1.6;
}

li {
  line-height: 1.6;
}

</style>
